import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// CSS
import 'styles/KeesSmitPartner.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import { BannerCustom } from 'components/Elements/Elements'
import BrandSlider from 'components/Brands/BrandSlider'

// Third Party
import _ from 'lodash'


function KooloosRomazo() {

  const {
    KooloosRomazo: {
      acf: {
        page_content: {
          banner: overOnsBanner,
          banner2: overOnsBanner2,
          content: overOnsContent,
          image: {
            localFile: {
              childImageSharp: {
                fluid: overOnsImage
              }
            }
          }
        }
      },
      yoast_meta: yoast
    },
  } = useStaticQuery(graphql`
  {
    KooloosRomazo: wordpressPage(wordpress_id: {eq: 714}) {
      acf {
        page_content {
          banner {
            button_text
            text
          }
          banner2 {
            button_text
            text
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          content
        }
      },
      yoast_meta {
        name
        content
        property
      }
    },
  }`)

  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="kees-smit-partner">
        <h1 className="d-none">Kooloos Zon en Huis | Thuis in elk seizoen</h1>
        <BackgroundImage className="kees-smit-partner-image" fluid={overOnsImage} />
        <BannerCustom to="/contact" className="color-background-secondary" bannerText={overOnsBanner.text} bannerButtonText={overOnsBanner.button_text} />
        <div className="container py-5">
          <Content content={overOnsContent} />
        </div>
        <BannerCustom to="/contact" className="color-background-main" bannerText={overOnsBanner2.text} bannerButtonText={overOnsBanner2.button_text} />
        <BrandSlider title="Onze Merken" className="py-5 my-5" />
      </div>
    </Layout>
  )
}

export default KooloosRomazo